//
//
//
//
//
//
//

import Vue from 'vue';
import IndexLayout from './layouts/index.vue';

export default {
  components: {
    IndexLayout,
  },
  computed: {},
  mounted() {},
};
