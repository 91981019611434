import Vue from 'vue';

import VueRouter from 'vue-router';
Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'hash',
    // base: '/www/',
    routes: [
        {
            path: '/',
            component: () => import('../view/test.vue')  // component: IndexPage
        },
        {
            path: '/test-painter',
            component: () => import('../view/test2.vue')  // component: IndexPage
        },
        {
            path: '/shop/:shop_id/:poster_id',
            component: () => import('../view/shop.vue')
        },
        {
            path: '/product/:shop_id/:product_id/:poster_id',
            component: () => import('../view/product.vue')
        },
        {
            path: '/quotation/:shop_id/:product_id/:quotation_id',
            component: ()=> import('../view/quotation.vue')
        },

        {
            path: '/shop2/:shop_id/:poster_id',
            component: () => import('../view/shop2.vue')
        },
        {
            path: '/product2/:shop_id/:product_id/:poster_id',
            component: () => import('../view/product2.vue')
        },
        //test wx47907b09adda20de wx.config
        {
            path: '/test/4',
            component: () => import('../../components/painter4.vue')  // component: IndexPage
        },

        {
            name: 'ShopPosterChoosePage',
            path: '/poster/shop/:shop_id',
            component: ()=> import('../view/poster/shop/choose.vue'),
        },
        {
            name: 'ProductPosterChoosePage',
            path: '/poster/product/:shop_id/:product_id',
            component: ()=> import('../view/poster/product/choose.vue'),
        },


        {
            path: '/goods-report',
            name: 'GoodsReportPage',
            component: ()=> import('../view/tool/goods-report.vue'),
        },

        {
            path: '/tact',
            name: 'TactPage',
            component: ()=> import('../view/tool/tact.vue'),
        },

        {
            path: '/airline',
            name: 'AirlinePage',
            component: ()=> import('../view/tool/airline.vue'),
        },


        {
            path: '/flight-plan',
            name: 'FlightPlanPage',
            component: ()=> import('../view/tool/flight-plan.vue'),
        },

        {
            path: '/hs-code',
            name: 'HcPage',
            component: ()=> import('../view/tool/hs-code.vue'),
        },

        {
            path: '/iata',
            name: 'IataPage',
            component: ()=> import('../view/tool/iata.vue'),
        },

        {
            path: '/plane-model',
            name: 'PlaneModelPage',
            component: ()=> import('../view/tool/plane-model.vue'),
        },

    ]
});

router.beforeEach((to, from, next) => {
    console.log(to.fullPath, to.path,  location.href)
    next()
});


export default router