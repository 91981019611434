/* eslint-disable */
import Vue from 'vue'
import router from './page/router';
import index from './App.vue';
import Meta from  'vue-meta';
import { Button, Field, Image as VanImage, 
    Dialog, ActionSheet, Icon, Picker, Popup, 
    Form , Toast, Tag, Cell, CellGroup, Tab, 
    Tabs, DatetimePicker, Row, Col, Lazyload, Search,
    Radio, RadioGroup, Checkbox, CheckboxGroup,
    Grid, GridItem, Loading, Slider, Overlay,
} from 'vant';

import '@/assets/css/global.css';
import '@/assets/css/icon.css';
import 'vant/lib/index.css';
// import './utils/wxShare';

// import * as Sentry from '@sentry/browser';
// import * as Integrations from '@sentry/integrations';
// import { Integrations as TracingIntegrations } from "@sentry/tracing";
// process.env.NODE_ENV == 'production' && Sentry.init({
//   dsn: 'http://94406b3b771e44f69b4df41c6cbb8a63@sentry.yctop.com/4',
//   integrations: [
//     new Integrations.Vue({Vue, attachProps: true}),
//     new TracingIntegrations.BrowserTracing({
//       startTransactionOnPageLoad: false,
//     }),
//   ],
//   release: process.env.VUE_APP_RELEASE_VERSION,  //版本号
//   logErrors: true, //开发环境下打印错误
//   // _experiments: { useEnvelope: true },
// });

Vue.use(Button);
Vue.use(Field);
Vue.use(VanImage);
Vue.use(Dialog);
Vue.use(ActionSheet);
Vue.use(Icon);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Form);
Vue.use(Toast);
Vue.use(Tag);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(DatetimePicker);
Vue.use(Col);
Vue.use(Row);
Vue.use(Lazyload);
Vue.use(Meta);
Vue.use(Search);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Loading);
Vue.use(Slider);
Vue.use(Overlay);


new Vue({
    render: h => h(index),
    router,
    metaInfo(){
        return {
            title: '',
        }
    },
}).$mount('#app')

